import { Injectable } from '@angular/core';
import constantURL from '../../../shared/constantURL';
import { BasePostService } from '../../../shared/services/basePostService.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubmitBuyerInfoService extends BasePostService {
  private response = new Subject<SubmitBuyerInfoResponse | null>();
  currentResponse = this.response.asObservable();
  data: SubmitBuyerInfoResponse | null;

  _path = constantURL.product.extraInfo.submitBuyerInfo;

  async _make() {
    const data = await super._make() as unknown as SubmitBuyerInfoResponse;
    this.data = data.done ? data : null;
    return data;
  }
}


type Buyer = {
  Name: string;
  Email: string;
  Phone: string;
  Age: string;
};

type PaymentAttempt = {
  ManualPayment: boolean;
  id: number;
  providerRefNum: string;
  NewProductId: number;
  BusinessId: number;
  status: string;
  ReferenceNumberId: number;
  paymentOptions: number;
  subTotal: number;
  discount: number;
  fees: number;
  taxes: number;
  purchaseFess: number;
  roundUpAmount: number;
  Amount: number;
  isReturend: boolean;
  quantity: number;
  marketingFees: number;
  marketingFeesOnBuyer: number | null;
  marketingSourceId: number | null;
  updatedAt: string;
  createdAt: string;
};

type TempTransaction = {
  id: number;
  payment_option: number;
  provider_id: number;
  transaction_ref_num: number;
  merchant_ref_num: string;
  original_amount: string;
  merchant_id: number;
  createdAt: string;
  updatedAt: string;
  is_migrated: boolean;
  status: string;
};

type Provider = {
  success: boolean;
  status: number;
  voucher: string;
  tempTrx: TempTransaction;
  expiry: string;
  expire_duration: number;
};

export type SubmitBuyerInfoResponse = {
  Buyers: Buyer[];
  PaymentAttempt: PaymentAttempt;
  Provider: Provider;
  refNum: string;
  done: boolean;
};
