import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CONSTANT_CUSTOMER_SUPPORT_INFO } from 'src/app/shared/ConstantCustomerSupportInfo';
import constantURL from '../../shared/constantURL';
import { ActionsService } from '../../shared/services/actions.service';
import { AuthService } from '../../shared/services/auth.service';
import { CustomPaymentService } from '../../shared/services/custom-payment.service';
import { GetBusinessProfileService } from '../../shared/services/get-business-profile.service';
import { GetPendingRequestsService } from '../../shared/services/getPendingRequests.service';
import { LanguageService } from '../../shared/services/language.service';
import { User } from '../user';
import { AdvertisementDialogComponent } from './advertisement-dialog/advertisement-dialog.component';
import { GetAuthBusinessService } from 'src/app/shared/services/get-auth-business.service';
import { SeoService } from 'src/app/public-website/buyer/services/seo.service';
declare let $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public authenticated: boolean = false;
  public mode: string = '';
  public name: string = '';
  public subscription!: Subscription;
  public user!: User;
  public bussinessStatus: string = '';
  public contractStatusShippedOrSigned: boolean = false;
  public actions: any[] = [];
  public hasPayPermission: boolean = false;
  public sharedLink: string = constantURL.BASE_FRONT_URL;
  public paymentLink: string = '';
  public qrCode: string = '';
  public logo: string;
  public socialMediaBtns: string[] = ['facebook', 'twitter', 'linkedin'];
  public btnsSize = -1;
  public pendingRequests: any;
  public languages = ['ar', 'en'];
  public currentLanguages: string = 'en';
  public CustomerSupportPhone1: string = CONSTANT_CUSTOMER_SUPPORT_INFO.phone1;
  public CustomerSupportPhone: string = CONSTANT_CUSTOMER_SUPPORT_INFO.phone;
  public CustomerSupportWorkingHours: string =
    CONSTANT_CUSTOMER_SUPPORT_INFO.workingHours;
  private advanceSalesAction: any;
  private businessHasAdvancesSales = false;
  constructor(
    private authService: AuthService,
    private getBusinessProfileService: GetBusinessProfileService,
    private actionsService: ActionsService,
    private router: Router,
    private getPendingRequests: GetPendingRequestsService,
    private paymentService: CustomPaymentService,
    private languageService: LanguageService,
    private snackBar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformID: any,
    private translate: TranslateService,
    private dialogRef: MatDialog,
    private getAuthBusinessService: GetAuthBusinessService,
    private seoService: SeoService
  ) {}

  async openAdvertisementDialog() {
    const isSeller = await this.authService.isSellerOrisAdmin('Seller');
    const show = localStorage.getItem('dont-show-advertisement');
    if (show !== 'true' && isSeller) {
      this.dialogRef.open(AdvertisementDialogComponent);
    }
  }

  async ngOnInit(): Promise<void> {
    this.currentLanguages = localStorage.getItem('language');
    this.translate.use(this.currentLanguages);
    this.currentLanguages === 'ar'
      ? document.body.classList.add('dir-rtl')
      : document.body.classList.remove('dir-rtl');

    this.seoService.generateTags({
      title: 'EasyKash - Your Online Payment Gateway',
      description: SeoDescription,
    });

    document.getElementById('whatsapp-chat-widget').style.display = 'block';
    //await this.openAdvertisementDialog();
    if (isPlatformBrowser(this.platformID)) {
      this.authenticated = this.authService.isAuthenticated();
      const isAdmin = await this.authService.isSellerOrisAdmin('Admin');
      const isSeller = await this.authService.isSellerOrisAdmin('Seller');
      if (this.authenticated) {
        if (isSeller) {
          this.mode = 'seller';
          await this.actionsService.hasAccessToPaymentOptions();
          this.actions = await this.actionsService.getActions();
          this.advanceSalesAction = this.actionsService.getAdvanceSalesAction();
        } else if (isAdmin) {
          this.mode = 'admin';
          this.actions = await this.actionsService.getActions();
          //Remove Duplications
          let uniqueArray: any[] = this.actions.filter((item, pos) => {
            return this.actions.indexOf(item) == pos;
          });
          this.actions = uniqueArray;
        }
        await this.getUserData(this.mode);
      } else {
        this.actions = await this.actionsService.getActions();
      }
      $(document).ready(function () {
        if (screen.width >= 1068) {
          document.getElementById('mainContainer')!.style.display = 'block';
        } else {
          document.getElementById('mainContainer')!.style.display = 'none';
        }
      });
      let permissions = await this.authService.getPermissions();
      this.hasPayPermission = permissions.some(
        (item: { clientURL: string; [key: string]: any }) => {
          return item.clientURL == '/pay';
        }
      );
    }
  }

  async getUserData(mode: string) {
    if (mode === 'seller') {
      const sellerResponse = await this.getBusinessProfileService.make();
      if (sellerResponse.error && sellerResponse.error.name == 'ForceLogout') {
        this.onLogout();
        AuthService.deleteAllCookies();
        location.reload();
      }
      this.logo = sellerResponse.Business.logo;
      this.user = sellerResponse;
      this.name = sellerResponse.Business.name;
      let encodedName = encodeURIComponent(this.name)
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29');
      let payLink: string = this.sharedLink + encodedName + '/pay';
      this.paymentLink = payLink;
      this.qrCode = await this.generateQRCode();
      this.checkStatus();
      this.businessHasAdvancesSales =
        sellerResponse.Business.enabel_advancedSales;
      if (this.businessHasAdvancesSales)
        this.businessHasAdvancesSales = await this.hasPermissionbyActionLink(
          this.advanceSalesAction.routerLink
        );
      //console.log('seller', this.user);
    } else if (mode === 'admin') {
      this.user = await this.getBusinessProfileService.make();
      this.pendingRequests = await this.getPendingRequests.make();
    }
  }

  displayMainContainer() {
    const mainContainer = document.getElementById('mainContainer');
    if (mainContainer!.style.display == 'block') {
      mainContainer!.style.display = 'none';
    } else {
      mainContainer!.style.display = 'block';
    }
  }

  public openOrgPopup() {
    document.getElementById('org-popup')!.classList.add('d-block');
    document.getElementById('org-collapseMenu')!.classList.add('d-block');
  }

  openPopup() {
    document.getElementById('popup')!.classList.add('d-block');
    document.getElementById('collapseMenu')!.classList.add('d-block');
  }

  closePopup() {
    document.getElementById('popup')!.classList.remove('d-block');
  }

  openModal(target: string) {
    document.getElementById('overlay')!.style.display = 'block';
    document.getElementById(target)!.style.display = 'block';
  }

  closeModal(id: string) {
    document.getElementById(id)!.style.display = 'none';
    document.getElementById('overlay')!.style.display = 'none';
  }

  printDetails(e: any) {
    let parentTarget = e.target.parentElement;
    if (parentTarget!.classList.contains('menu-a')) {
      if (parentTarget.parentElement!.classList.contains('active')) {
        parentTarget.parentElement!.classList.remove('active');
      } else {
        var items = document.getElementsByClassName('menu');
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove('active');
        }
        parentTarget.parentElement!.classList.add('active');
      }
    } else {
      if (parentTarget!.classList.contains('active')) {
        parentTarget!.classList.remove('active');
      } else {
        var items = document.getElementsByClassName('menu');
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove('active');
        }
        parentTarget!.classList.add('active');
      }
    }
  }

  async onLogout() {
    this.authService.logout();
    this.getAuthBusinessService.clear();
  }

  closeVerify() {
    document.getElementById('verify')!.style.display = 'none';
  }

  async checkStatus() {
    let bussiness_status: any = await this.getBusinessProfileService.make();
    this.bussinessStatus = bussiness_status.Business.activeStatus;
    this.contractStatusShippedOrSigned =
      bussiness_status.Business.contractStatus === 'shipped' ||
      bussiness_status.Business.contractStatus === 'signed';
  }

  async showShop(name: string) {
    await this.router.navigate([name]);
    //window.location.reload();
  }

  async copyUrl(link: string) {
    let message = '';
    try {
      const selBox = document.createElement('textarea') as HTMLTextAreaElement;
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = link;
      document.body.appendChild(selBox);
      selBox.select();
      const success = document.execCommand('copy');
      document.body.removeChild(selBox);
      message = success
        ? this.translate.instant('Sellers.Products.CopiedToClipboard')
        : this.translate.instant('Sellers.Products.UnableToCopy');
    } catch (err) {
      message = this.translate.instant('Sellers.Products.UnableToCopy');
    } finally {
      this.snackBar.open(message, '', { duration: 2000 });
    }
  }

  async generateQRCode() {
    this.paymentService.setParams({ link: this.paymentLink });
    let res = await this.paymentService.make();
    return res.qrcode;
  }

  downloadQrCode() {
    var a = document.createElement('a');
    a.href = `${this.qrCode}`;
    a.download = 'qrcode.png';
    a.click();
  }

  openVerifyModal() {
    const closeVerify = document.getElementById('verify');
    if (closeVerify!.style.display == 'block') {
      closeVerify!.style.display = 'none';
    } else {
      closeVerify!.style.display = 'block';
    }
  }

  handleSubActionClick(subAction) {
    if (subAction.redirectLink) {
      window.open(subAction.redirectLink, '_blank').focus();
    }
    if (window.innerWidth <= 1068) {
      document.getElementById('mainContainer')!.style.display = 'none';
    }
  }

  translateAction(action) {
    if (this.currentLanguages === 'ar') {
      return action.actionAr;
    } else if (this.currentLanguages === 'en') {
      return action.action;
    }
  }

  translateSubName(sub) {
    if (this.currentLanguages === 'ar') {
      return sub.nameAr;
    } else if (this.currentLanguages === 'en') {
      return sub.name;
    }
  }
  navigateToWithdrawals() {
    this.router.navigate(['/admin/reports/withdrawals']);
  }
  navigateToActivation() {
    this.router.navigate(['/admin/requests/ActivationRequest']);
  }
  async hasPermissionbyActionLink(actionLink: string) {
    const permissions: Array<any> = await this.authService.getPermissions();
    return (
      permissions.find((item: { clientURL: string; [key: string]: any }) => {
        return item.clientURL == actionLink;
      }) != null
    );
  }
  getSubActionMappedNotifcation(sub) {
    if (!this.pendingRequests) return;
    const {
      pendingWithdrawals,
      pendingActivations,
      pendingSmsMarketing,
      pendingBankAccounts,
      pendingContracts,
      pendingAdvancedSales,
    } = this.pendingRequests;
    switch (sub.name) {
      case 'Withdrawals Requests':
        return pendingWithdrawals;
      case 'Activation Requests':
        return pendingActivations;
      case 'Contracts':
        return pendingContracts;
      case 'Bank Accounts Requests':
        return pendingBankAccounts;
      case 'Sms Marketing Requests':
        return pendingSmsMarketing;
      case 'Advanced Sales Requests':
        return pendingAdvancedSales;
    }
  }

  changeLanguage(language: string): void {
    this.languageService.changeLanguage(language);
    this.currentLanguages = language;
    document.body.setAttribute('lang', language);
    language === 'ar'
      ? document.body.classList.add('dir-rtl')
      : document.body.classList.remove('dir-rtl');
  }
}

const SeoDescription =
  'EasyKash helps you setup a Payment Gateway in Egypt for Free Online. Collect Payments in 3 minutes by credit card, fawry, cash collection and more!';
