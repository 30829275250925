import { Injectable } from '@angular/core';
import constantURL from '../constantURL';
import { BasePostService } from './basePostService.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FetchPAymentGroupForSeller extends BasePostService {
  _path = constantURL.product.fields.paymentOptions.getProductPaymentGroups;
  productAllowedOptions =  new BehaviorSubject([]);;


    async _make() {
      const data = await super._make() as unknown as {name: string, nameAr}[];
      this.productAllowedOptions.next(data?.length ? data : []);
      return data;
    }
}
