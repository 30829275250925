<div *ngIf="authenticated">
  <!--Top Navbar-->
  <nav class="top-navbar d-flex bg-white w-100 fixed-top">
    <div class="logo-nav-container">
      <img class="logo" src="../../../assets/images/easykash-logo.png" alt="logo" />
      <img id="menuIcon" class="menu-icon" src="../../../assets/images/svg/menu.svg" alt=""
        (click)="displayMainContainer()" />
    </div>
    <div class="d-flex align-items-center me-4 content-nav-container">
      <div class="col-auto mx-auto pending-container" *ngIf="mode === 'admin'">
        <span>
          <button (click)="navigateToWithdrawals()" class="nav-button">Pending Withdrawals: </button>
          <span class="fw-bold text-primary">{{pendingRequests?.pendingWithdrawals ? pendingRequests.pendingWithdrawals
            : 0}}</span>
        </span>
        <span class="ps-3">
          <button (click)="navigateToActivation()" class="nav-button">Pending Activations: </button>
          <span class="fw-bold text-primary">{{pendingRequests?.pendingActivations ? pendingRequests.pendingActivations
            : 0}}</span>
        </span>
      </div>
      <div class="dashboard-top-navbar bg-white" *ngIf="mode === 'seller'">
        <a routerLink="/seller/referrals">{{ 'Sellers.Dashboard.InviteEarn' | translate }}</a>
        <ng-container *ngIf="advanceSalesAction">
          <a [routerLink]="advanceSalesAction.routerLink" [id]="advanceSalesAction.action"
            *ngIf="businessHasAdvancesSales">
            <span>{{ translateAction(advanceSalesAction) }}</span>
          </a>
        </ng-container>
        <a (click)="showShop(name)">{{ 'Sellers.Dashboard.Shop' | translate }}</a>
        <a (click)="openModal('customerSupport')">{{ 'Sellers.Dashboard.CustomerSupport' | translate }}</a>
        <a href="https://play.google.com/store/apps/details?id=com.EasyKash">{{ 'Sellers.Dashboard.DownloadEasyKash' |
          translate }}</a>
        <a *ngIf="hasPayPermission" (click)="openModal('customerPayment')">{{ 'Sellers.Dashboard.CustomPayment' |
          translate }}</a>
      </div>
      <div class="col-auto ms-auto d-flex align-items-center">
        <button mat-button [matMenuTriggerFor]="menu" class="btn">
          <span>
            <svg width="20" height="20" viewBox="0 0 20 20" class="global-icon">
              <path fill="#0f75bc"
                d="M17.071 2.929C15.182 1.04 12.671 0 10 0 7.329 0 4.818 1.04 2.929 2.929 1.04 4.818 0 7.329 0 10c0 2.671 1.04 5.182 2.929 7.071C4.818 18.96 7.329 20 10 20c2.671 0 5.182-1.04 7.071-2.929C18.96 15.182 20 12.671 20 10c0-2.671-1.04-5.182-2.929-7.071zM8.103 1.378c-.335.326-.697.715-1.06 1.167-.627.783-1.15 1.625-1.558 2.511H2.689c1.249-1.84 3.174-3.186 5.414-3.678zM2.02 6.228H5.02c-.341 1.02-.54 2.087-.592 3.186H1.192c.074-1.133.364-2.208.827-3.186zm-.827 4.358h3.236c.052 1.099.25 2.166.592 3.186h-3c-.464-.978-.754-2.053-.828-3.186zm1.497 4.358h2.796c.408.885.93 1.728 1.558 2.51.362.453.725.842 1.06 1.168-2.24-.492-4.165-1.838-5.414-3.678zm6.725 3.29c-.417-.36-.936-.863-1.457-1.512-.455-.568-.845-1.162-1.17-1.778h2.627v3.29zm0-4.462H6.26c-.381-1.009-.6-2.073-.658-3.186h3.813v3.186zm0-4.358H5.601c.057-1.113.277-2.177.658-3.186h3.155v3.186zm0-4.358H6.787c.318-.604.699-1.185 1.142-1.743.528-.663 1.059-1.178 1.485-1.546v3.29zm9.394 4.358h-3.236c-.052-1.099-.25-2.166-.592-3.186h3c.464.978.754 2.053.828 3.186zm-1.497-4.358h-2.796c-.408-.885-.93-1.728-1.558-2.51-.362-.453-.725-.842-1.06-1.168 2.24.492 4.165 1.838 5.414 3.678zm-6.725-3.29c.417.36.936.863 1.457 1.512.455.568.845 1.162 1.17 1.778h-2.627v-3.29zm0 4.462h3.155c.381 1.009.6 2.073.658 3.186h-3.813V6.228zm0 4.358h3.813c-.057 1.113-.277 2.177-.658 3.186h-3.155v-3.186zm0 7.647v-3.29h2.627c-.318.605-.699 1.186-1.142 1.744-.528.663-1.059 1.178-1.485 1.546zm1.31.39c.336-.327.699-.716 1.061-1.168.628-.783 1.15-1.625 1.558-2.511h2.796c-1.249 1.84-3.174 3.186-5.414 3.678zm6.085-4.851H14.98c.341-1.02.54-2.087.592-3.186h3.236c-.074 1.133-.364 2.208-.827 3.186z">
              </path>
            </svg>
            <span class="lang-name">{{currentLanguages.toUpperCase()}}</span>
          </span>
        </button>
        <div class="menu show-profile-menu" *ngIf="user && mode === 'seller'">
          <div class="menu-b">
            <div class="profile-container px-3 py-2" (click)="openOrgPopup()">
              <span class="fw-bold text-primary">
                <img src="../../../assets/icons/team.svg" alt="Bussiness image" height="35" width="35"
                  class="img-fluid border border-2 bg-primary rounded-circle ms-2 me-1" />
               {{'Sellers.Dashboard.Branches' |translate}}              </span>
            </div>
          </div>
        </div>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngFor="let lang of languages"
            (click)="changeLanguage(lang)">{{lang.toUpperCase()}}</button>
        </mat-menu>
        <div class="col-auto" (click)="openPopup()" *ngIf="user">
          <span class="fw-bold text-primary">{{user.first_name + ' ' + user.last_name}}</span>
          <img [src]="user?.logo ? user.logo: '../../../assets/images/avatar.jpg'" alt="avatar" height="35" width="35"
            class="img-fluid border border-2 border-primary rounded-circle ms-2 me-1" />
          <img height="10" src="../../../assets/images/svg/down.svg" alt="down" />
        </div>
      </div>
    </div>

    <!--Popup Container-->
    <div class="overlay" id="popup" style="display: none" (click)="closePopup()">
      <div class="collapse-menu" id="collapseMenu">
        <div class="menu-top-bg" *ngIf="user">
          <p>{{user.first_name + ' ' + user.last_name}}</p>
          <img src="../../../assets/images/svg/cancel.svg" alt="cancel" class="close-btn position-absolute"
            (click)="closePopup()" />
        </div>
        <div class="user-intro-menu">
          <div class="user-menu-img">
            <img [src]="user?.logo ? user.logo: '../../../assets/images/avatar.jpg'" alt="avatar" />
          </div>
          <div class="user-name-menu" *ngIf="user">
            <h4>{{user.first_name + ' ' + user.last_name}}</h4>
            <p>{{user.email}}</p>
          </div>
        </div>
        <div class="menu-link">
          <a class="menu-a-link" routerLink="/admin/update-password" *ngIf="mode === 'admin'">
            <img src="../../../assets/images/svg/settings.svg" alt="Settings" />
            <span>{{ 'Sellers.Dashboard.Settings' | translate }}</span>
          </a>
          <a class="menu-a-link" routerLink="/seller/settings/personal" *ngIf="mode === 'seller'">
            <img src="../../../assets/images/svg/settings.svg" alt="Settings" />
            <span>{{ 'Sellers.Dashboard.Settings' | translate }}</span>
          </a>
          <a class="menu-a-link" (click)="openModal('customerSupport')" *ngIf="mode === 'seller'">
            <img src="../../../assets/images/svg/information.svg" alt="Information" />
            <span>{{ 'Sellers.Dashboard.HelpSupport' | translate }}</span>
          </a>
        </div>
        <a class="menu-a-link logout" (click)="onLogout()">
          <img src="../../../assets/images/svg/on-off-button.svg" alt="Off Button" />
          <span>{{ 'Sellers.Dashboard.Logout' | translate }}</span>
        </a>
      </div>
    </div>
    <app-organization-pop [currentBusinessName]="name"></app-organization-pop>
  </nav>

  <!-- Main Content-->
  <div class="main-container">
    <!-- Side Left Menu -->
    <div class="menu-container" id="mainContainer">
      <div class="inner-menu-container">
        <div class="menu" (click)="printDetails($event)" *ngFor="let action of actions">
          <a [routerLink]="action.routerLink" class="menu-a" [id]="action.action">
            <img [src]="'../../../assets/icons/'+ action.icon" [alt]="action.action" height="20" />
            <span>{{ translateAction(action) }}</span>
          </a>
          <a class="sub-menu" [routerLink]="sub.routerLink" (click)="handleSubActionClick(sub)"
            *ngFor="let sub of action.subActions" routerLinkActive="active">
            <div class="sub-a" style="display:flex;justify-content: space-between;">
              <span>{{ translateSubName(sub) }}</span>
              <span *ngIf="action.action=='Admin Requests' && getSubActionMappedNotifcation(sub)" class="notifyIcon">
                {{getSubActionMappedNotifcation(sub) }}
              </span>
            </div>
          </a>
        </div>
        <div class="menu show-profile-menu" (click)="printDetails($event)">
          <a class="menu-a" routerLink="/seller/referrals">{{ 'Sellers.Dashboard.InviteEarn' | translate }}</a>
        </div>
        <div class="menu show-profile-menu" (click)="printDetails($event)">
          <a class="menu-a" (click)="showShop(name)">{{ 'Sellers.Dashboard.Shop' | translate }}</a>
        </div>
        <div class="menu show-profile-menu" (click)="printDetails($event)">
          <a class="menu-a" (click)="openModal('customerSupport')">{{ 'Sellers.Dashboard.CustomerSupport' | translate
            }}</a>
        </div>
        <div class="menu show-profile-menu" (click)="printDetails($event)">
          <a class="menu-a" href="https://play.google.com/store/apps/details?id=com.EasyKash">{{
            'Sellers.Dashboard.DownloadEasyKash' | translate }}</a>
        </div>
        <div class="menu show-profile-menu" (click)="printDetails($event)">
          <a class="menu-a" *ngIf="hasPayPermission" (click)="openModal('customerPayment')">{{
            'Sellers.Dashboard.CustomPayment' | translate }}</a>
        </div>
        <div class="menu show-profile-menu" *ngIf="user && mode === 'seller'">
          <div class="menu-b">
            <div class="profile-container px-3 py-2" (click)="openOrgPopup()">
              <span class="fw-bold text-white">
                <img src="../../../assets/icons/team.svg" alt="Bussiness image" height="35" width="35"
                  class="img-fluid border border-2 border-white rounded-circle ms-2 me-1" />
               {{'Branches' |translate}}
              </span>
            </div>
          </div>
        </div>
        <div class="menu show-profile-menu" *ngIf="user">
          <div class="menu-b">
            <div class="profile-container px-3 py-2" (click)="openPopup()">
              <span class="fw-bold text-white">{{user.first_name + ' ' + user.last_name}}</span>
              <img src="../../../assets/images/avatar.jpg" alt="avatar" height="35" width="35"
                class="img-fluid border border-2 border-white rounded-circle ms-2 me-1" />
            </div>
          </div>
        </div>
        <button class="verify" (click)="openVerifyModal()"
          *ngIf="mode === 'seller' && bussinessStatus !== 'Verified' && !contractStatusShippedOrSigned">
          <img src="../../../assets/images/svg/correct.svg" alt="verify" />
          <span *ngIf="bussinessStatus == 'UnVerified'">{{ 'Sellers.Dashboard.GetVerified' | translate }}</span>
          <span *ngIf="bussinessStatus == 'Active'">{{ 'Sellers.Dashboard.ConfirmContract' | translate }}</span>

          <span class="icon-container" id="alertIcon" style="display:block;">
            <mat-icon class="icon">error</mat-icon>
            <span class="icon-background"></span>
          </span>
        </button>
      </div>
    </div>

    <!-- Content -->
    <div class="content-container col">
      <div class="inner-content p-4">
        <router-outlet></router-outlet>
        <ng-container *ngIf="mode === 'seller'">
          <!-- <app-image-slider></app-image-slider> -->
        </ng-container>
      </div>
    </div>
  </div>

  <!--Popup Container-->
  <div class="overlay" id="overlay" style="display: none">
    <!--For Customer Support-->
    <div class="customer-support-container" id="customerSupport">
      <div class="customer-support-top">
        <h1>{{ 'Sellers.Dashboard.CustomerSupport' | translate }}</h1>
        <img src="../../../assets/images/svg/cancel.svg" alt="cancel" class="close-btn"
          (click)="closeModal('customerSupport')" />
      </div>
      <span class="customer-support-tag border-bottom">
        {{ 'Sellers.Dashboard.ContactCustomerSupport' | translate }}
      </span>
      <div class="p-3">
        <div>{{ 'Sellers.Dashboard.Phone' | translate }}: <span class="text-secondary no-rtl">{{CustomerSupportPhone1}}
            &nbsp;-&nbsp; {{CustomerSupportPhone}}</span></div>
        <div>{{ 'Sellers.Dashboard.WorkingHours' | translate }}: <span
            class="text-secondary no-rtl">{{CustomerSupportWorkingHours}}.</span></div>
      </div>
    </div>
    <!--For Customer Payment-->
    <div class="customer-support-container" id="customerPayment">
      <div class="customer-support-top">
        <h3>{{ 'Sellers.Dashboard.CustomPayment' | translate }}</h3>
        <img src="../../../assets/images/svg/cancel.svg" alt="cancel" class="close-btn"
          (click)="closeModal('customerPayment')" />
      </div>
      <div class="pt-3 text-center">
        <span class="fw-bold">{{ 'Sellers.Dashboard.CustomPaymentLink' | translate }}: </span>
        <span class="btn p-0 border-0 rounded-0 text-primary border-bottom border-primary"
          (click)="copyUrl(paymentLink)">
          <mat-icon class="text-dark">content_copy</mat-icon> &nbsp;{{paymentLink}}
        </span>
      </div>
      <div class="row mx-0 justify-content-center pb-4">
        <div class="col-md-5 text-center">
          <img class="img-fluid" width="200" height="200" src="{{qrCode}}" alt="qr" />
          <button type="button" class="btn btn-primary" (click)="downloadQrCode()">
            {{ 'Sellers.Dashboard.DownloadQR' | translate }}
          </button>
        </div>
        <div class="col-md-7 d-flex align-items-center justify-content-center py-3">
          <app-share [size]="btnsSize" [url]="paymentLink"></app-share>
        </div>
      </div>
    </div>
  </div>

  <div id="verify" class="verify-account-container"
    *ngIf="mode === 'seller' && bussinessStatus !== 'Verified' && !contractStatusShippedOrSigned">
    <div class="top-radius-circle">
      <div class="position-relative">
        <p *ngIf="bussinessStatus == 'UnVerified'">{{ 'Sellers.Dashboard.GetVerified' | translate }}</p>
        <p *ngIf="bussinessStatus == 'Active'">{{ 'Sellers.Dashboard.ConfirmContract' | translate }}</p>
        <img id="closeVerify" src="../../../assets/images/svg/cancel.svg" alt="Close Verify" (click)="closeVerify()" />
      </div>
    </div>
    <app-verify></app-verify>
    <app-seller-contract-confirmation></app-seller-contract-confirmation>
  </div>
</div>


<div *ngIf="!authenticated" class="my-5 mx-auto" style="width: 50%;">
  <div class="card text-center align-center ">

    <div class="card-body">
      <h5 class="card-title text-danger">SESSION IS EXPIRED</h5>
      <p class="card-text">You have been inactive for more than 60 minutes.<br> Please login to continue using Easykash
      </p>
      <a routerLink="/login" class="btn btn-primary">Login</a>
    </div>
  </div>

</div>
