import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { MetaTags } from '../buyer';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private meta: Meta,
    private titleService: Title,
  ) {}

  generateTags(tags: MetaTags) {
    if (tags.title) {
      /* General */
      this.titleService.setTitle(tags.title as string);
      this.meta.updateTag({
        name: 'description',
        content: tags.description as string,
      });
      /* Twitter */
      this.meta.updateTag({
        name: 'twitter:title',
        content: tags.title as string,
      });
      this.meta.updateTag({
        name: 'twitter:description',
        content: tags.description as string,
      });
      this.meta.updateTag({
        name: 'twitter:image',
        content: tags.image as string,
      });
      this.meta.updateTag({
        name: 'twitter:card',
        content: 'summary',
      });
      this.meta.updateTag({
        name: 'twitter:site',
        content: '@EasyKash',
      });
      /* OG */
      this.meta.updateTag({
        property: 'og:title',
        content: tags.title as string,
      });
      this.meta.updateTag({
        property: 'og:url',
        content: `${environment.BASE_URL}${tags.slug}`,
      });
      this.meta.updateTag({ property: 'og:type', content: 'website' });
      this.meta.updateTag({
        property: 'og:description',
        content: tags.description as string,
      });
      this.meta.updateTag({
        property: 'og:image',
        content: tags.image as string,
      });
      this.meta.updateTag({ property: 'og:site_name', content: 'EasyKash' });
      if (tags.slug) {
        this.meta.updateTag({
          rel: 'canonical',
          href: `${window.location.href}`,
        }); //  tell search engines that a specific URL represents the master copy of a page
      }
    }
  }
}
