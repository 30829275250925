import { Injectable } from '@angular/core';
import { CalculateService } from './calculate.service';
import { ViewProductInfoHandlerService } from './view-product-info-handler.service';
import { ProductTypes } from 'src/app/shared/product/productTypes';
import Cookies from 'js-cookie';

@Injectable({
  providedIn: 'root'
})
export class GetCalculatesParamsService {
  public limit!: number;
  params: {
    productId: number,
    quantity: number,
    paymentOptions?: string,
    tickets?: object[],
    codeName?: string,
    productType: string,
    package: object
  } = {
      productId: 0,
      quantity: 0,
      paymentOptions: '',
      tickets: [],
      codeName: '',
      productType: '',
      package: {}
    };
  Dividing = 1;

  constructor(
    public vpihs: ViewProductInfoHandlerService,
    public calculate: CalculateService
  ) { }

  getParams() {
    return this.params;
  }
  getDividing() {
    return this.Dividing;
  }
  setParams(params: any) {
    this.params = params;
  }
  setCodeName(val: string) {
    this.params['codeName'] = val;
  }
  setTickets(val: object[]) {
    this.params['tickets'] = val;
  }

  setQuantity(val: number) {
    this.params['quantity'] = val;
  }
  setPackage(val: object) {
    this.params['package'] = val
  }
  setProductId(val: number) {
    this.params['productId'] = val;
  }

  setPaymentOptions(val: string) {
    this.params['paymentOptions'] = val;
  }

  setProductType(type: string) {
    this.params['productType'] = type;
  }

  async calculateInfos() {
    const outputTickets = [];
    if (this.vpihs.viewInfoObject && this.vpihs.viewInfoObject?.extras && !this.vpihs.viewInfoObject?.extras?.isCustomPayment) {
      if (this.vpihs.viewInfoObject.extras.isBooking) {
        for (const slot of this.vpihs.viewInfoObject.extras.selectedSlots) {
          const newSlot = {
            itemId: slot.slotId,
            description: slot.slotName,
            price: Number(slot.slotPrice),
            quantity: Number(slot.slotQuantity)
          };
          outputTickets.push(newSlot);
        }
      } else {
        for (const ticket of this.vpihs.viewInfoObject.extras.selectedTickets) {
          const newTicket = {
            itemId: ticket.ticketId,
            description: ticket.ticketName,
            price: Number(ticket.ticketPrice),
            quantity: Number(ticket.ticketQuantity)
          };
          outputTickets.push(newTicket);
        }
      }
    }
    if (this.vpihs.viewInfoObject?.extras?.isCustomPayment) {
      if (this.vpihs.productId) {
        this.setProductId(this.vpihs.productId)
        this.setQuantity(1)
        this.setProductType(ProductTypes.customPayment)
      } else {
        return;
      }
    }
    const source = this.trackData(this.vpihs.productId);
    this.calculate.setParams({ ...this.params, "tickets": outputTickets, source });
    let result = await this.calculate.make();
    let Dividing = 1;
    if (this.vpihs.currency && this.vpihs.currency != 'EGP') {
      Dividing = this.getDividingRate(this.vpihs.currency, result.rate);
    } else {
      //Currently there is a case that produce vpihs.currency == null
      //to avoid this we set the vpihs.currency = 'EGP' this is the default case
      this.vpihs.currency = 'EGP';
    }
    this.vpihs.Dividing = Dividing;
    this.Dividing = result.Dividing;
    this.vpihs.fees = result.paymentOptionFees;
    if (result.splitFees || result.addFees) {
      this.vpihs.fees += result.Fees;
    }
    if (result.mAddFees && source){
      this.vpihs.fees += result.mFees
    }
    this.vpihs.fees += result.VATOnSellerFlag ? 0 : result.VAT;
    this.vpihs.fees += result.productFees;
    this.vpihs.fees += result.roundUpAmount;
    this.vpihs.fees = Number((this.vpihs.fees / Dividing).toFixed(2));
    // this.vpihs.roundUpAmount = result.roundUpAmount / Dividing;
    this.vpihs.discount = -result.discountValue / Dividing;
    this.vpihs.taxes = result.Taxes / Dividing;
    this.vpihs.subTotal = result.originalAmount / Dividing;
    this.vpihs.total = result.amountPaidByBuyer / Dividing;
    this.vpihs.merchantFees = result.Fees / Dividing;
    this.vpihs.vat = result.VAT / Dividing;
    this.vpihs.feesWithNoPaymentOptionFees = this.vpihs.fees - result.paymentOptionFees;
    if(source){
      this.vpihs.mFees = result.mFees / Dividing;
      this.vpihs.mAddFees = result.mAddFees;
    }
  }

  trackData(prodId){
    let existingData = [];
  let source = "";
  const localStorageData = localStorage.getItem('trackingData') ? JSON.parse(localStorage.getItem('trackingData')) : [];
  const cookieData = Cookies.get('trackingData') ? JSON.parse(Cookies.get('trackingData')) : [];

  if (localStorageData.length >= cookieData.length) {
    existingData = localStorageData;
  } else {
    existingData = cookieData;
  }

  existingData.forEach((item) => {
    if (item.prodId == prodId) {
      source = item.source;
      return source;
    }
    return source;
  });
  return source;
}

  getDividingRate(currency: string, rates: rate[]){
    for (let rateInfo of rates) {
      if (rateInfo['name'] == `${currency}EGP`) {
        return rateInfo['rate'];
      }
    }
    return 1
  }

  async setPaymentOption(paymentOption: string){
    this.vpihs.setPaymentOption(paymentOption)
    this.setPaymentOptions(paymentOption);
    await this.calculateInfos();
  }

  clone(externalDep: {vpihs: ViewProductInfoHandlerService, calculateService: CalculateService}){
    const protoType = Object.getPrototypeOf(this);
    const clone = Object.create(protoType) as GetCalculatesParamsService;

    clone.limit = this.limit;
    clone.params =  {...this.params};
    clone.Dividing = this.Dividing;

    clone.vpihs = externalDep.vpihs;
    clone.calculate = externalDep.calculateService;

    return clone
  }

}

interface rate {
  name: string
  rate: number
  [key: string]: any
}
