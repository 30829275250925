import { EventEmitter, Injectable, ViewChild } from '@angular/core';
import { GetBasicStepOneTemplateService } from './get-basic-step-one-template.service';
import { PostBasicStepOneTemplateService } from './post-basic-step-one-template.service';
import {
  PaymentOptionBox,
  StepOneControllers,
} from '../components/basic-info/types';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { BASIC_FORM_TEMPLATE } from 'src/app/shared/constantProductTypeInfos';

@Injectable({
  providedIn: 'root',
})
export class BasicStepOneTemplateService {
  private objectToPatch: StepOneControllers;
  public paymentOptionsEmitter = new EventEmitter<PaymentOptionBox[] | null>();

  constructor(
    private getBasicStepOneTemplateService: GetBasicStepOneTemplateService,
    private postBasicStepOneTemplateService: PostBasicStepOneTemplateService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {}

  async postData(data: StepOneControllers) {
    this.postBasicStepOneTemplateService.setParams({
      formValues: data,
    });
    await this.postBasicStepOneTemplateService.make();
  }

  private async getData(): Promise<StepOneControllers> {
    try {
      const data = await this.getBasicStepOneTemplateService.make();
      return data.formValues as StepOneControllers;
    } catch (e) {
      const message = this.translateService.instant(
        'FORM_TEMPLATE.FAIL_TO_GET'
      );
      this.snackBar.open(message, '', { duration: 2000 });
    }
    return null;
  }

  // patch only keys that has values.
  async getObjectToPatch(
    productName: string = BASIC_FORM_TEMPLATE.PRODUCT_NAME
  ): Promise<StepOneControllers | null> {
    const data = await this.getData();
    const controllersKeys = data? Object.keys(data): [];

    let hasValue = false;
    this.objectToPatch = {};

    const keyToBeReplaced = BASIC_FORM_TEMPLATE.PRODUCT_NAME;
    const keyToBeReplacedReg = new RegExp(keyToBeReplaced, 'i');
    const productNameLc = productName.toLowerCase();
    const isEvent = productNameLc === 'event';
    for (let key of controllersKeys) {
      if (data[key] !== null) {
        let keyAfterMapping = key;
        const isQuestionKey = keyAfterMapping.slice(0, 2) === 'Do';

        if (key.toLowerCase().includes(keyToBeReplaced)) {
          productName = isQuestionKey ? productNameLc : productName;
          keyAfterMapping = key.replace(keyToBeReplacedReg, productName);
          if(isEvent && isQuestionKey){
            keyAfterMapping =
              keyAfterMapping == 'Do you want to show this event on your shop?'
                ? 'Do you want this event on your shop?'
                : keyAfterMapping;
          }
        }

        this.objectToPatch[keyAfterMapping] = data[key];
        hasValue = true;
      }
    }
    this.paymentOptionsEmitter.emit(
      this.objectToPatch['Payment options'] ?? null
    );
    return hasValue ? this.objectToPatch : null;
  }

  showFailPopup(instantKey = 'FORM_TEMPLATE.TRY_AGAIN_TEXT') {
    Swal.fire({
      icon: 'error',
      title: this.translateService.instant('FORM_TEMPLATE.ERROR_TITLE'),
      text: this.translateService.instant(instantKey),
    });
  }

  showSuccessPopup(instantKey = 'FORM_TEMPLATE.SAVED') {
    Swal.fire({
      icon: 'success',
      title: this.translateService.instant('FORM_TEMPLATE.DONE'),
      text: this.translateService.instant(instantKey),
    });
  }
}
