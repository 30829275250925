import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _moment from 'moment';
import { MandatoryFieldsService } from '../../../services/mandatory-fields.service';
import { BaseField } from '../../baseField';
import moment from 'moment';

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'LL LT',
  datePickerInput: 'LL',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};
@Component({
  selector: 'app-datetime-range',
  templateUrl: './datetime-range.component.html',
  styleUrls: ['./datetime-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatetimeRangeComponent extends BaseField implements OnInit {
  public dateTimeRange: Date[] = [];
  public nextDay: Date;
  public startAt: Date;
  mandatoryDateRanges: string[] = ['Event Duration'];
  @Input() formControlRef: FormControl = new FormControl();
  @Input() mandatory: boolean = false;
  @Input() dateTimeWithHoursOnly: boolean = false;

  constructor(private mandatoryFieldsService: MandatoryFieldsService) {
    super();
  }

  ngOnInit() {
    this.mandatory = this.mandatoryFieldsService.isMandatory(this.mandatoryDateRanges, this.fieldObject.fieldName) || false;
    if(this.dateTimeWithHoursOnly){
      this.nextDay = moment().add(1, 'day').startOf('day').toDate();
      this.startAt = this.nextDay;
    }

    this.checkAndMarkTouched();

    //
  }

  checkAndMarkTouched() {
    if (this.formControlRef.errors) {
      this.formControlRef.markAsTouched();
    }
  }
/**
   * 
   * @param event Handle both single date & time(dueDate)
   * also handle "start-end" date time (eventDuration)
   */
  handleChange(event: any) {
    if (!event.value) {
      return;
    } 
    //single date and time (claims > dueDate)
    if (!Array.isArray(event.value)) {
      const selectedDate = moment(event.value).startOf('hour').toDate();
  
      if (this.dateTimeWithHoursOnly && selectedDate) {
        this.startAt = selectedDate;
      }
  
      return this.formControlRef.setValue(selectedDate);
    } else { // start and end date time  (eventDuration)
      if (event.value.length !== 2) {
        return;
      }
  
      const [startDate, endDate] = event.value.map(date =>
        moment(date).startOf('hour')
      );
  
      if (!startDate.isValid() || !endDate.isValid()) {
        return;
      }
  
      const now = moment().startOf('hour');
  
      if (startDate.isBefore(now) || endDate.isBefore(now)) {
        this.formControlRef.setErrors({ pastDate: true });
        return;
      } 
  
      this.formControlRef.setErrors(null);
      this.formControlRef.setValue([startDate.toDate(), endDate.toDate()]);
    }}

  translateFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar") {
      return fieldObject.fieldNameAr;
    } else {
      return fieldObject.fieldName;
    }
  }
}
