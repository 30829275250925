import { Injectable } from '@angular/core';
import { BasePostService } from 'src/app/shared/services/basePostService.service';
import constantURL from '../../../../shared/constantURL';

@Injectable({
  providedIn: 'root',
})
export class UpGrequestToPay extends BasePostService {
  _path = constantURL.UPG.requestToPay;
}
