<div mat-dialog-title>
  <div class="d-flex justify-content-end">
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="onClose()"
    ></button>
  </div>
</div>

<div mat-dialog-content>
  <div class="d-flex justify-content-start">
    <qrcode
      [qrdata]="qrcodeData"
      [width]="256"
      [errorCorrectionLevel]="'M'"
    ></qrcode>

    <p class="pt-4 text-wrap fs-lg fw-bold">
      Scan the QR code below to complete the <br />
      transaction using your mobile wallet <br />
      application. After the transaction is <br />
      complete, you will receive an email <br />
      confirmation from Easykash for your purchase.
      <br />
    </p>
  </div>
  <div>
    <p class="">
      <strong>OR</strong><br />
      Can't scan the QR code? send paymennt request to entered mobile number
    </p>

    <button type="button" class="btn btn-primary" (click)="requestToPay()">
      Request
    </button>
  </div>
</div>

<div mat-dialog-actions></div>
